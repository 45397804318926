import { Sort } from '@angular/material/sort';
import { PagingOptions } from '../../../shared/page.models';
import { JobListFilters } from './job-list.state';

export class SetSort {
  static readonly type = '[JobListState] SetSort';
  constructor(public readonly payload: Sort) {}
}

export class SetPaging {
  static readonly type = '[JobListState] SetPaging';
  constructor(public readonly payload: PagingOptions) {}
}

export class SetFilters {
  static readonly type = '[JobListState] SetFilters';
  constructor(public readonly payload: JobListFilters) {}
}

import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Region } from '../../../region/region.models';
import { RegionState } from '../../../region/region.state';
import { PagingOptions } from '../../../shared/page.models';
import { Job } from '../../job.models';
import { SetFilters, SetPaging, SetSort } from './job-list.actions';

export interface JobListFilters {
  createdDateFrom?: Date;
  createdDateTo?: Date;
  id?: string;
  invocationId?: string;
  projectId?: string;
  pointCloudId?: string;
  runId?: string;
  tiduuId?: string;
}

export interface JobListStateModel {
  sort: Sort;
  paging: PagingOptions;
  filters: JobListFilters;
}

const defaultState: JobListStateModel = {
  sort: { active: <keyof Job>'createdUtc', direction: 'desc' },
  paging: { size: 10, index: 0 },
  filters: {},
};

@State<JobListStateModel>({
  name: 'jobListState',
  defaults: defaultState,
})
@Injectable()
export class JobListState {
  @Select(RegionState.activeRegion) activeRegion$!: Observable<Region | undefined>;

  @Selector() static sort(state: JobListStateModel): Sort {
    return state.sort;
  }

  @Selector() static paging(state: JobListStateModel): PagingOptions {
    return state.paging;
  }

  @Selector() static filters(state: JobListStateModel): JobListFilters {
    return state.filters;
  }

  @Selector() static filterCount(state: JobListStateModel): number {
    return Object.values(state.filters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;
  }

  ngxsAfterBootstrap(ctx: StateContext<JobListStateModel>): void {
    this.activeRegion$
      .pipe(distinctUntilChanged((prev, current) => prev?.regionCode === current?.regionCode))
      .subscribe(() => {
        const resetPaging: PagingOptions = { ...ctx.getState().paging, index: 0 };
        ctx.patchState({ paging: resetPaging });
      });
  }

  @Action(SetSort) setSort(ctx: StateContext<JobListStateModel>, { payload }: SetSort) {
    ctx.patchState({ sort: payload });
  }

  @Action(SetPaging) setPaging(ctx: StateContext<JobListStateModel>, { payload }: SetPaging) {
    ctx.patchState({ paging: payload });
  }

  @Action(SetFilters) setFilters(ctx: StateContext<JobListStateModel>, { payload }: SetFilters) {
    ctx.patchState({ filters: payload });
  }
}
